<template>
  <div class="cart pt-3 pt-md-0">
    <!-- <section
      class="px-3 pt-md-3 pb-0 px-md-5 pt-md-5 pb-md-0 mt-0 mt-md-0 mt-lg-0"
    > -->
    <NavbarOne
      class="card-top d-md-none d-lg-none"
      id="card-top"
      showNavbar
      showSearch
    >
    </NavbarOne>
    <!-- </section> -->
    <v-card class="card-top ma-3 mt-16 ma-md-5">
      <custom-section class="section">
        <v-row ref="form">
          <v-col cols="12" lg="8">
            <v-card
              class="card pa-3 mb-3 pa-md-5 mb-md-5"
              outlined
              elevation="1"
            >
              <div class="mb-5">
                <heading-card
                  bold
                  color="dark-blue"
                  :title="`${$t('field.branch')} & ${$t(
                    'page.cart.customer_information'
                  )}`"
                />
              </div>
              <v-row v-if="profile != null">
                <v-col cols="12" sm="6">
                  <v-text-field
                    :value="profile.first_name"
                    :label="$t('field.first_name')"
                    outlined
                    hide-details
                    disabled
                    filled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :value="profile.last_name"
                    :label="$t('field.last_name')"
                    outlined
                    hide-details
                    disabled
                    filled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :value="profile.email"
                    :label="$t('field.email')"
                    outlined
                    hide-details
                    disabled
                    filled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :value="profile.phone"
                    :label="$t('field.phone')"
                    outlined
                    hide-details
                    disabled
                    filled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    :items="branch"
                    ref="branch"
                    :label="$t('field.branch')"
                    item-text="name"
                    item-value="id"
                    v-model="payload.branch"
                    dense
                    disabled
                    filled
                    outlined
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" lg="4">
            <v-card
              class="card pa-3 mb-3 pa-md-5 mb-md-5"
              outlined
              elevation="1"
            >
              <div class="d-flex justify-space-between align-content-start">
                <heading-card
                  bold
                  color="dark-blue"
                  :title="$t('page.cart.order_review')"
                />
                <div @click="collapse.order = !collapse.order">
                  <icons
                    icon="icon-arrow-collapse"
                    :class="{ 'collapse--active': collapse.order }"
                  />
                </div>
              </div>
              <template v-if="!collapse.order">
                <card-cart-item
                  v-if="servicePackage != null"
                  :name="servicePackage.name"
                  :price="`IDR ${formatPrice(servicePackage.prices[0].amount)}`"
                  hideClose
                />
              </template>
            </v-card>
            <v-card class="card pa-3 pa-md-5" outlined elevation="1">
              <div class="d-flex justify-space-between align-content-start">
                <heading-card
                  :class="{ 'mb-4': !collapse.payment }"
                  bold
                  color="dark-blue"
                  :title="$t('page.cart.billing_summary')"
                />
                <div @click="collapse.payment = !collapse.payment">
                  <icons
                    icon="icon-arrow-collapse"
                    :class="{ 'collapse--active': collapse.payment }"
                  />
                </div>
              </div>
              <template v-if="!collapse.payment">
                <div class="billing-box">
                  <div class="d-flex justify-space-between mb-3">
                    <heading-price
                      :text="$t('page.cart.subtotal')"
                      position="left"
                      color="gray"
                    />
                    <heading-price
                      :text="`IDR ${formatPrice(this.subTotal)}`"
                      position="right"
                      color="gray"
                      bold
                    />
                  </div>
                </div>
              </template>
              <div class="d-flex justify-space-between mt-3 mb-2">
                <heading-price
                  extraBold
                  :text="$t('page.cart.grand_total')"
                  position="left"
                  color="main"
                />
                <heading-price
                  :text="`IDR ${formatPrice(this.totalPayment)}`"
                  position="right"
                  color="main"
                  extraBold
                />
              </div>
              <div class="checkbox--booking-else mb-3">
                {{ $t("page.cart.by_clic_pay") }}
                <tnc />
              </div>

              <c-button
                bold
                shadow
                rounded
                fluid
                @click="payment"
                type="submit"
                :disabled="!show"
              >
                {{ $t("pay") }} IDR
                {{ formatPrice(this.totalPayment) }}</c-button
              >
            </v-card>
          </v-col>
        </v-row>
      </custom-section>
      <v-dialog v-model="dialogConfirmation" persistent max-width="290">
        <v-card class="pa-3">
          <div class="d-flex justify-center mb-3">
            <v-icon x-large color="yellow"> mdi-information-outline </v-icon>
          </div>
          <p class="text-center">
            {{ $t("coming_soon_package") }}
          </p>

          <div class="d-flex justify-center">
            <c-button
              class="mx-2 py-2"
              rounded
              outline
              @click="
                () => {
                  dialogConfirmation = false;
                  setBranchBack();
                }
              "
              >{{ $t("ok") }}</c-button
            >
          </div>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import CustomSection from "@/components/v2/section/index.vue";
import HeadingCard from "@/components/Heading/HeadingCard.vue";
import Icons from "@/components/elements/Icons.vue";
import CardCartItem from "@/components/v2/card/card_cart_item.vue";
import HeadingPrice from "@/components/Heading/HeadingPrice.vue";
import CButton from "@/components/v2/button/index.vue";
import Tnc from "@/components/v2/tnc/index.vue";
import API from "@/service/api_service";
import Auth from "@/service/auth_service";
import moment from "moment";
import NavbarOne from "@/components/v2/topbar/navbar_one.vue";

export default {
  metaInfo() {
    return {
      script: [
        {
          src: process.env.VUE_APP_MIDTRANS_URL,
          "data-client-key": process.env.VUE_APP_MIDTRANS_CLIENT_KEY,
          defer: true,
          callback: () => this.$store.commit("loadMidtrans"),
        },
      ],
    };
  },
  components: {
    CustomSection,
    HeadingCard,
    Icons,
    CardCartItem,
    HeadingPrice,
    CButton,
    Tnc,
    NavbarOne,
  },
  data: () => ({
    branch: [],
    profile: null,

    payload: {
      branch: 12,
    },
    show: true,
    collapse: {
      order: false,
      payment: false,
      voucher: false,
    },
    subTotal: 0,
    totalPayment: 0,
    totalDiscount: 0,
    errorMessages: "",
    midtransStatus: 406,
    servicePackage: null,
    dialogConfirmation: false,
  }),
  watch: {
    payload: {
      handler() {
        this.show = true;
      },
      deep: true,
    },
    "payload.branch": function () {
      this.getPackage();
      console.log(this.payload.branch, "branch");
    },
    notForMe: function (val) {
      if (val) {
        this.getfamily();
      }
    },
    "payload.family_id": function (val) {
      if (val) {
        const indexFamily = this.myFamily.findIndex(
          item => item.patient_id == val
        );
        if (indexFamily > -1) {
          this.payloadForOther = this.myFamily[indexFamily];
        }
      }
    },
  },
  computed: {
    dateFormatted() {
      return this.formatDate(this.payload.booking_date);
    },
    birthDateFormatted() {
      return this.payloadForOther.birthDate == ""
        ? ""
        : this.formatDate(this.payloadForOther.birthDate);
    },
  },
  created() {
    if (this.$route.query.plant == "" || this.$route.query.plant == undefined) {
      this.$router.push(`/${this.$i18n.locale}/404`);
    }
    this.getPlant();
    this.getProfile();
    this.getPackage();
    if (this.$route.query.transaction_status) {
      if (this.$route.query.transaction_status == "pending") {
        this.$router.push(`/${this.$i18n.locale}/payment-pending-subscribe?order_id=${this.$route.query.order_id}&status_code=${this.$route.query.status_code}&transaction_status=${this.$route.query.transaction_status}`);
      } else {
        this.$router.push(`/${this.$i18n.locale}/payment-success-subscribe?order_id=${this.$route.query.order_id}&status_code=${this.$route.query.status_code}&transaction_status=${this.$route.query.transaction_status}`);
      }
    }
  },
  methods: {
    sortByName(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    },
    async getPlant() {
      const resp = await API.get(
        `${process.env.VUE_APP_API_TRUST}plant/get`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      );
      if (resp.statusCode == 200) {
        this.branch = await resp.results.filter(item => {
          // dissable selain nrc bali
          // return item.type == "BLA" && (item.id == 12 || item.id == 4);
          return item.type == "VIRTU DIGILABA";
        });
      }
    },
    // sementara pake get all karena belum ada yang by id
    async getPackage() {
      try {
        const respSub = await API.get(
          `${process.env.VUE_APP_API_TRUST}/package/get?plant_id=${this.payload.branch}&subscription=true`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (respSub.statusCode === 200) {
          const getIndexPakage = respSub.results.findIndex(
            subscribe => subscribe.id == this.$route.params.id
          );
          if (getIndexPakage > -1) {
            this.servicePackage = respSub.results[getIndexPakage];
            this.totalPayment =
              respSub.results[getIndexPakage].prices[0].amount;
            this.subTotal = respSub.results[getIndexPakage].prices[0].amount;
          }
        } else {
          this.$router.push(`/${this.$i18n.locale}/404`);
        }
      } catch (error) {
        console.log("Error 403!");
      }
    },
    async getProfile() {
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}profile`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        this.profile = {
          email: resp.person.personal_info.contacts.email,
          first_name: resp.person.first_name,
          last_name: resp.person.last_name,
          phone: resp.person.personal_info.contacts.mobile_phone_number,
        };
      } catch (error) {
        console.log("Error 403!");
      }
    },
    formatPrice(price) {
      return `${Intl.NumberFormat().format(price)}.00`;
    },

    async payment() {
      // submit reagis order
      try {
        const dataReady = [
          {
            orders: [
              {
                package_id: this.servicePackage.id,
                price: this.servicePackage.prices[0].amount,
              },
            ],
            plant_id: this.payload.branch,

            client_unique_id: "tm617a34c4a3b86",
          },
        ];
        const order = await API.post(
          `${process.env.VUE_APP_API_TRUST}registration/buy-package`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          dataReady
        );
        if (order.statusCode === 200) {
          if (
            order.results.length > 0 &&
            order.results[0].midtrans_token != null
          ) {
            if (this.$store.state.midtransIsLoaded) {
              const vuethis = this;

              window.snap.pay(order.results[0].midtrans_token, {
                onSuccess: function (result) {
                  vuethis.midtransStatus = result.statusCode;
                  vuethis.$router.push(
                    `/${vuethis.$i18n.locale}/payment-success-subscribe?order_id=${result.order_id}&status_code=${result.status_code}&transaction_status=${result.transaction_status}`
                  );
                },
                onPending: function (result) {
                  vuethis.midtransStatus = result.statusCode;
                  API.get(
                    `${process.env.VUE_APP_API_TRUST}registration/status-transaction?invoice_number=${result.order_id}`,
                    {
                      Authorization: `Bearer ${Auth.getToken()}`,
                    }
                  ).then(res => {
                    if (res.status_code == 200) {
                      vuethis.midtransStatus = res.status_code;
                      vuethis.$router.push(
                        `/${vuethis.$i18n.locale}/payment-success-subscribe?order_id=${result.order_id}&status_code=${result.status_code}&transaction_status=${result.transaction_status}`
                      );
                    } else {
                      // vuethis.$router.push(
                      //   `/${vuethis.$i18n.locale}/payment-pending-subscribe?order_id=${result.order_id}&status_code=${result.status_code}&transaction_status=${result.transaction_status}`
                      // );
                      vuethis
                        .$swal({
                          text: vuethis.$t("the_order_has_been_canceled"),
                          showCancelButton: false,
                          confirmButtonColor: "#f7685b",
                          confirmButtonText: vuethis.$t("close"),
                        })
                        .then(() => {
                          vuethis.$router.push(`/patient`);
                        });
                    }
                  });
                },
                onClose: function () {
                  vuethis
                    .$swal({
                      text: vuethis.$t("the_order_has_been_canceled"),
                      showCancelButton: false,
                      confirmButtonColor: "#f7685b",
                      confirmButtonText: vuethis.$t("close"),
                    })
                    .then(() => {
                      vuethis.$router.push(`/patient`);
                    });
                },
                onError: function (result) {
                  vuethis.midtransStatus = result.statusCode;
                  vuethis.$swal({
                    icon: "error",
                    text: "Error payment!",
                  });
                },
              });
            }
          } else {
            this.midtransStatus = 0;
            this.$router.push(`/${this.$i18n.locale}/payment-success-subscribe?order_id=${this.$route.query.order_id}&status_code=${this.$route.query.status_code}&transaction_status=${this.$route.query.transaction_status}`);
          }
        } else if (order.statusCode == 403) {
          Object.keys(order.errors).forEach(element => {
            this.toast(
              "error",
              typeof order.errors[element] == "object"
                ? order.errors[element][0]
                : order.errors[element]
            );
          });
        } else {
          this.$swal({
            icon: "error",
            text: order.message,
          });
        }
      } catch (error) {
        this.$swal({
          icon: "error",
          text: this.$t("error_booking"),
        });
      }
    },
    formatDate(date) {
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
  },
};
</script>
<style lang="scss">
.v-text-field--outlined.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot {
  min-height: 40px !important;
}
</style>
>
<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.cart {
  .register-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: gray;
    span {
      color: $main_2;
      cursor: pointer;
    }
  }
  .count-item {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 0;
  }
  .billing-box {
    border-bottom: 1px solid #e0e0e0;
  }
  .checkbox {
    &--booking-else {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: gray;
      text-transform: none;
    }
  }
  .voucher {
    margin-bottom: 20px;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
  .collapse--active {
    transform: rotate(180deg);
  }
  a {
    text-decoration: none;
  }
}
</style>
<style lang="scss">
.cart {
  .v-text-field {
    &.v-input--dense {
      input {
        font-size: 14px !important;
      }
    }
  }
  .v-select {
    &.v-input--dense {
      font-size: 14px !important;
    }
  }
}
</style>
